
const partners = [
    {
        name: "Partner 1",
        link: "www.google.com",
    },
    {
        name: "Partner 2",
        link: "www.google.com",
    },
    {
        name: "Partner 3",
        link: "www.google.com",
    }
]
export default partners;